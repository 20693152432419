import React from 'react';

import {BodyText, BodyHeading, AiH, AiB, AiP} from './Styles/TermText'
 
import {
    Accordion,
    AccordionItem,
} from 'react-accessible-accordion';

import './Styles/styles.css'


export const CreditAuthorization = () => {
    return(
        <>
            <BodyHeading>Credit Authorization</BodyHeading>
            <BodyText>
                You acknowledge, agree, and authorize that (a) Fig Tech, Inc. and/or its related companies will obtain consumer and related information about you from one or more consumer reporting agencies for the purposes of identifying the lenders and/or financial providers who would be the best match for you, (b) your information may be sent to lenders and/or financial providers on your behalf, (c) your information may be used in order to make sure you are who you say you are, and to determine how much debt you currently have in order to determine your debt-to-income ratio, (d) such lenders and/or financial providers may obtain consumer reports and related information about you from one or more consumer reporting agencies, such as TransUnion, Experian and Equifax, (e) and your information may be used by lenders and/or service providers to make prequalification and other credit decisions.

                List of some of our lenders and/or financial providers: Avant, Best Egg, FreedomPlus, LendingClub, LendingPoint, Liberty Bank, LightStream, Marcus by Goldman Sachs, NetCredit, OneMain, OppLoans, Payoff, Prosper, RISE, SoFi, Upstart
            </BodyText>
        </>
    )
}


export const EConsent = () => {
    return(
            <>
            <BodyHeading>ELECTRONIC RECORDS DISCLOSURE AND AGREEMENT</BodyHeading>
                <BodyText>
                Please read this Electronic Records Disclosure and Agreement carefully and keep a copy for your records.
                </BodyText>
                <br/>
                <BodyHeading>Electronic Copy of Related Disclosures, Agreements and Instructions.</BodyHeading>
                <br/>
                <BodyText>
                This Agreement is between Fig Loans Texas, LLC (hereinafter referred together as “we,” “us,” “our,”) and each Customer who consents to receive electronic disclosures relating to any loan transactions with us, together with any person who is authorized by a Customer to use or access the Service (hereinafter referred together as “you,” “your”, “yours”, or “Customer”). We will provide you with disclosures relating to your transactions with us electronically, rather than by postal mail or in person. These disclosures may include, but are not limited to, disclosures required under the Electronic Signatures in Global and National Commerce Act, the Truth in Lending Act, the Gramm-Leach-Bliley Act, the Equal Credit Opportunity Act, the Fair Credit Reporting Act, the Texas Finance Code, and related regulations. In addition, your loan documents and loan statements will be transmitted to you electronically if you consent.
                </BodyText>
                <br/>
                <BodyHeading>Your Consent is Required.</BodyHeading> 
                <br/>
                <BodyText>
                You must consent to receiving these disclosures and agreements electronically before we can provide them to you in that manner.
                </BodyText>
                <br/>
                <BodyHeading>Paper Copy of Disclosures, Agreements and Instructions.</BodyHeading>
                <br/>
                <BodyText>
                If you do not want to receive the legal disclosures, agreements and the instructions electronically, you should exit this area of our web site. If you consent to receive the disclosures, agreements and instructions electronically, you can also request a paper copy of the related legal disclosures, agreements and instructions. We will charge you a fee of $20 for providing a paper copy of the disclosures, agreements and instructions.
                </BodyText>
                <br/>
                <BodyHeading>Withdrawal of Consent for Electronic Delivery.</BodyHeading>
                <br/>
                <BodyText>
                You may withdraw consent for electronic delivery these legal disclosures, agreements, and instructions, but doing so will not affect the legal effectiveness, validity or enforceability of electronic records that were made available to you prior to the implementation of your withdrawal of consent for electronic delivery. You can notify us of your intent to cancel electronic disclosures by:
                </BodyText>
                <br/>
                <BodyText>
                Sending a letter to:
                </BodyText>
                <BodyText>
                Fig Loans Texas, LLC
                </BodyText>
                <BodyText>
                2245 Texas Drive, Suite 300
                </BodyText>
                <BodyText>
                Sugar Land, Texas 77479
                </BodyText>
                <br/>
                <BodyText>
                Sending an email to: support@figloans.com
                </BodyText>
                <BodyText>
                Calling us at: 832-802-0344
                </BodyText>
                <br/>
                <BodyText>
                If you email or write us a letter, please be sure to identify yourself and the applicable accounts or transactions.
                </BodyText>
                <br/>
                <BodyText>
                In the event that you withdraw your consent for electronic delivery, we will no longer be able to communicate with you electronically.
                </BodyText>
                <br/>
                <BodyHeading>
                    System Requirements to Access the Information. 
                </BodyHeading>
                <br/>
                <BodyText>
                To receive an electronic copy of the requested file, you must have the following equipment and software:
                </BodyText>
                <br/>
                <ul>
                    <li>
                        You must have a personal computer or other access device, which is capable of accessing the Internet (e.g., you must have a modem and available phone line, a cable Internet connection or some other means of access to the Internet, and you must have an active account with an Internet service provider). Your access to this page verifies that your system meets these requirements.
                    </li>
                    <li>
                        You must have an Internet web browser which is capable of supporting 128-bit SSL encrypted communications. Your access to this page verifies that your browser and encryption software meet these requirements.
                    </li>
                    <li>
                        You must have software which permits you to receive and access Portable Document Format or “PDF” files, such as Adobe Acrobat Reader® version 5.1 or higher (available for downloading at http://www.adobe.com/products/acrobat/readstep2.html).
                    </li>
                </ul>
                <BodyHeading>
                System Requirements to Retain the Information. 
                </BodyHeading>
                <br/>
                <BodyText>
                To retain a copy of the requested file, your system must have the ability to either download (e.g., to your hard disk drive or a floppy diskette) or print PDF files. You must have a functioning printer connected to your personal computer or other access device, which is able to print the related legal disclosures, agreements, the instructions, communications, and online statements on plain white 8½ x 11 inch paper.
                </BodyText>
                <br/>
                <BodyHeading>
                Updating Your Contact Information.
                </BodyHeading>
                <br/>
                <BodyText>
                To provide updated information on how we may contact you electronically, you must notify us by:
                </BodyText>
                <br/>
                <BodyText>
                Sending a letter to:
                </BodyText>
                <BodyText>
                Fig Loans Texas, LLC
                </BodyText>
                <BodyText>
                2245 Texas Drive, Suite 300
                </BodyText>
                <BodyText>
                Sugar Land, Texas 77479
                </BodyText>
                <br/>
                <BodyText>
                Sending an email to: support@figloans.com
                </BodyText>
                <BodyText>
                Calling us at: 832-802-0344
                </BodyText>
                <br/>
                <BodyHeading>
                Contractual Agreements/Modification.
                </BodyHeading>
                <br/>
                <BodyText>
                This Agreement supplements and modifies other agreements that you may have with us. To the extent that this Agreement and another agreements contain conflicting provisions, this Agreement will govern the delivery of electronic statements, notices and disclosures, but all other contractual obligations of the parties remain subject to the terms of any other agreements. For example, you will still be required to review any account statements you receive and notify us within established time periods if there are any errors on your statement.
                </BodyText>
                <br/>
                <BodyHeading>
                Authorization Consent.
                </BodyHeading>
                <br/>
                <BodyText>
                By agreeing to the terms and conditions of this Agreement, you represent that you are authorized to enter into this Agreement for all persons who own or are authorized to access this account, and that such persons will be bound by the terms of this Agreement.
                </BodyText>
                <br/>
                <BodyHeading>
                Changes to this Agreement.
                </BodyHeading>
                <br/>
                <BodyText>
                We reserve the right to terminate this Agreement. We will notify you of any changes as required by applicable law.
                </BodyText>
                <br/>
                <BodyHeading>
                Agreements and Acknowledgments.
                </BodyHeading>
                <br/>
                <BodyText>
                You agree that selecting the “I agree” button, on our account creation page, constitutes your signature, acceptance and agreement as if actually signed by you in writing. Further, you agree that no certification authority or other third-party verification is necessary to validate your electronic signature, and that the lack of such certification or third-party verification will not in any way affect the enforceability of your signature or any resulting contract between you and us.
                </BodyText>
            </>
    )
}


export const TermsOfServiceText = () => {
    return(
    <Accordion allowZeroExpanded>
        <AccordionItem>
            <AiH><AiB>Terms</AiB></AiH>
            <AiP>
                <BodyText>
                By accessing this website of Fig Tech Inc, any related webpages, or any contents on those webpages or websites (collectively, the “Website”), you agree to be bound by these Website Terms and Conditions of Use, all applicable laws and regulations and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing the Website. The materials contained in this Website are protected by applicable copyright and trade mark law.
                </BodyText>
            </AiP>
        </AccordionItem>
        <AccordionItem>
            <AiH><AiB>Use License</AiB></AiH>
            <AiP>
                <BodyText>
                    Permission is granted to temporarily download one copy of the materials (information or software) on Fig Tech Inc's Website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
                    <ul>
                        <li>i. modify or copy the materials;</li>
                        <li>ii. use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
                        <li>iii. attempt to decompile or reverse engineer any software contained on Fig Tech Inc's Website;</li>
                        <li>iv. remove any copyright or other proprietary notations from the materials; or</li>
                        <li>v. transfer the materials to another person or "mirror" the materials on any other server.</li>
                    </ul>
                    This license shall automatically terminate if you violate any of these restrictions and may be terminated by Fig Tech Inc at any time for any reason. Upon terminating your viewing of these materials, or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.
                </BodyText>
            </AiP>
        </AccordionItem>
        <AccordionItem>
            <AiH><AiB>Disclaimer</AiB></AiH>
            <AiP>
                <BodyText>
                    Fig Tech Inc's Website and the contents thereof are provided "as is" and with all faults. There are no warranties which extend beyond these Terms and Conditions of Use. Fig Tech Inc makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights belonging to you or any third-parties. Further, Fig Tech Inc does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on the Website or otherwise relating to such materials or on any sites linked to the Website.
                </BodyText>
            </AiP>
        </AccordionItem>
        <AccordionItem>
            <AiH><AiB>Limitations</AiB></AiH>
            <AiP>
                <BodyText>
                    In no event shall Fig Tech Inc or its employees, representatives, officers, directors, owners, contractors, or suppliers be liable for any damages (including, without limitation, direct damages, indirect damages, consequential damages, damages for loss of data or profit, or damages due to business interruption,) arising out of the use of the Website or inability to use the Website, even if Fig Tech Inc or a Fig Tech Inc employee or authorized representative has been notified orally or in writing of the possibility of such damage.
                </BodyText>
            </AiP>
        </AccordionItem>
        <AccordionItem>
            <AiH><AiB>Revisions and Errata</AiB></AiH>
            <AiP>
                <BodyText>
                    The materials appearing on Fig Tech Inc's Website could include technical, typographical, or photographic errors. Fig Tech Inc does not represent or warrant that any of the materials on its web site are accurate, complete, or current. Fig Tech Inc may make changes to the materials contained on the Website any time without notice. Fig Tech Inc does not, however, make any commitment to update the materials.
                </BodyText>
            </AiP>
        </AccordionItem>
        <AccordionItem>
            <AiH><AiB>Links</AiB></AiH>
            <AiP>
                <BodyText>
                    Fig Tech Inc has not reviewed all of the sites linked to from the Website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Fig Tech Inc of the site. Use of any such linked web site or content is at the user's own risk.
                </BodyText>
            </AiP>
        </AccordionItem>
        <AccordionItem>
            <AiH><AiB>Site Terms of Use Modifications</AiB></AiH>
            <AiP>
                <BodyText>
                    Fig Tech Inc may revise these terms of use for the Website at any time without notice. By using this Website you are agreeing to be bound by the then current version of these Terms and Conditions of Use.
                </BodyText>
            </AiP>
        </AccordionItem>
        <AccordionItem>
            <AiH><AiB>Governing Law</AiB></AiH>
            <AiP>
                <BodyText>
                    Any claim relating to Fig Tech Inc's Website shall be governed by the laws of the State of Delaware without regard to its conflict of law provisions. Some states do not allow limitations of implied warranties, so the above limitation may not apply to you. Some states do not allow the exclusion or limitation of incidental or consequential damages, so the above limitation or exclusion may not apply to you. If any provision of these Terms and Conditions of Use shall be invalid or prohibited under applicable law, such provision shall be ineffective only to the extent of such prohibition or invalidity, without invalidating the remainder of such provision or the remaining provisions .
                </BodyText>
            </AiP>
        </AccordionItem>
    </Accordion>
    )
}

export const PrivacyPolicyText = () => {
    return (
        <Accordion allowZeroExpanded>
            <AccordionItem>
                <AiH><AiB>Facts</AiB></AiH>
                <AiP>
                    <BodyHeading>Why?</BodyHeading>
                    <BodyText>
                        Financial companies choose how they share your personal information. Federal law gives consumers the right to limit some but not all sharing. Federal law also requires us to tell you how we collect, share, and protect your personal information. Please read this notice carefully to understand what we do.
                    </BodyText>
                    <BodyHeading>What?</BodyHeading>
                    <BodyText>
                        The types of personal information we collect and share depend on the product or service you have with us. The information can include:
                        <ul>
                            <li>Social Security number and Income</li>
                            <li>Employment information and transaction history</li>
                            <li>Account balances and payment history</li>
                            <li>Credit history and credit scores</li>
                        </ul>
                        All financial companies need to share customers’ personal information to run their everyday business. In the section below, we list the reasons financial companies can share their customers’ personal information; the reasons Fig Loans chooses to share; and whether you can limit this sharing.
                    </BodyText>
                    <BodyHeading>How?</BodyHeading>
                    <BodyText>
                        All financial companies need to share customers’ personal information to run their everyday business. In the section below, we list the reasons financial companies can share their customers’ personal information; the reasons Fig Loans chooses to share; and whether you can limit this sharing.
                    </BodyText>
                </AiP>
            </AccordionItem>
            <AccordionItem>
                <AiH><AiB>Reasons we can share your personal information</AiB></AiH>
                <AiP>
                    <BodyHeading>For our everyday business purposes –</BodyHeading>
                    <BodyText>
                        such as to process your transactions, maintain your account(s), respond to court orders and legal investigations, or report to credit bureaus
                    </BodyText>
                    <BodyText>
                        <table className="privacy-table">
                            <tr className="privacy-head">
                                <th>Does Fig Loans share?</th>
                                <th>Can you limit this sharing?</th>
                            </tr>
                            <tr>
                                <td>Yes</td>
                                <td>No</td>
                            </tr>
                        </table>
                    </BodyText>
                    <BodyHeading>For our marketing purposes –</BodyHeading>
                    <BodyText>
                        to offer our products and service to you
                    </BodyText>
                    <BodyText>
                    <table className="privacy-table">
                            <tr className="privacy-head">
                                <th>Does Fig Loans share?</th>
                                <th>Can you limit this sharing?</th>
                            </tr>
                            <tr>
                                <td>Yes</td>
                                <td>No</td>
                            </tr>
                        </table>
                    </BodyText>
                    <BodyHeading>For joint marketing with other financial companies</BodyHeading>
                    <BodyText>
                    <table className="privacy-table">
                            <tr className="privacy-head">
                                <th>Does Fig Loans share?</th>
                                <th>Can you limit this sharing?</th>
                            </tr>
                            <tr>
                                <td>Yes</td>
                                <td>No</td>
                            </tr>
                        </table>
                    </BodyText>
                    <BodyHeading>For our affiliates’ everyday business purposes –</BodyHeading>
                    <BodyText>
                        information about your transactions and experiences
                    </BodyText>
                    <BodyText>
                    <table className="privacy-table">
                            <tr className="privacy-head">
                                <th>Does Fig Loans share?</th>
                                <th>Can you limit this sharing?</th>
                            </tr>
                            <tr>
                                <td>Yes</td>
                                <td>No</td>
                            </tr>
                        </table>
                    </BodyText>
                    <BodyHeading>For our affiliates’ everyday business purposes –</BodyHeading>
                    <BodyText>
                    information about your creditworthiness
                    </BodyText>
                    <BodyText>
                    <table className="privacy-table">
                            <tr className="privacy-head">
                                <th>Does Fig Loans share?</th>
                                <th>Can you limit this sharing?</th>
                            </tr>
                            <tr>
                                <td>Yes</td>
                                <td>Yes</td>
                            </tr>
                        </table>
                    </BodyText>
                    <BodyHeading>For our affiliates to market to you</BodyHeading>
                    <BodyText>
                    <table className="privacy-table">
                            <tr className="privacy-head">
                                <th>Does Fig Loans share?</th>
                                <th>Can you limit this sharing?</th>
                            </tr>
                            <tr>
                                <td>Yes</td>
                                <td>Yes</td>
                            </tr>
                        </table>
                    </BodyText>
                    <BodyHeading>For our nonaffiliates to market to you</BodyHeading>
                    <BodyText>
                    <table className="privacy-table">
                            <tr className="privacy-head">
                                <th>Does Fig Loans share?</th>
                                <th>Can you limit this sharing?</th>
                            </tr>
                            <tr>
                                <td>Yes</td>
                                <td>Yes</td>
                            </tr>
                        </table>
                    </BodyText>
                </AiP>
            </AccordionItem>
            <AccordionItem>
                <AiH><AiB>To Limit Our Sharing</AiB></AiH>
                <AiP>
                    <BodyText>
                        <ul>
                            <li>E-mail Fig Loans at support@figloans.com</li>
                            <li>Visit our website: www.figloans.com</li>
                            <li>Mail-in the form below</li>
                        </ul>
                        Please note:
                        We can begin sharing your information 30 days from the date we sent or otherwise provided in this notice. when you are no longer our customer, we continue to share your information as describd in this notice. However, you can contact us at any time to limit our sharing.
                    </BodyText>
                    <BodyHeading>Questions?</BodyHeading>
                    <BodyText>Please email our Customer Service Team at support@figloans.com</BodyText>
                </AiP>
            </AccordionItem>
            <AccordionItem>
                <AiH><AiB>Who we are</AiB></AiH>
                <AiP>
                    <BodyHeading>Who is providing this notice?</BodyHeading>
                    <BodyText>
                        Fig Loans Texas, LLC
                        <br/>
                        2245 Texas Drive, Suite 300
                        <br/>
                        Sugar Land, TX 77479
                    </BodyText>
                </AiP>
            </AccordionItem>
            <AccordionItem>
                <AiH><AiB>What we do</AiB></AiH>
                <AiP>
                    <BodyHeading>How does Fig Loans Texas, LLC protect my personal information?</BodyHeading>
                    <BodyText>
                        To protect your personal information from unauthorized access and use, we use security measures that comply with federal law. These measures include computer safeguards and secured files and buildings.
                    </BodyText>
                    <BodyHeading>How does Fig Loans Texas, LLC collect my personal information?</BodyHeading>
                    <BodyText>
                        We collect your personal information, for example, when you:
                        <ul>
                            <li>Apply for a loan or give us your income information</li>
                            <li>Provide employment information or give us your employment history</li>
                            <li>Make payments on your loan</li>
                            <li>Give us your contact information</li>
                        </ul>
                    </BodyText>
                    <BodyText>
                        We also collect your personal information from others, such as credit bureaus, affiliates, or other companies.
                    </BodyText>
                    <BodyHeading>Why can’t I limit all sharing?</BodyHeading>
                    <BodyText>
                        Federal law gives you to right to limit some but not all sharing related to:
                        <ul>
                            <li>Sharing for affiliates’ everyday business purposes – information about your creditworthiness</li>
                            <li>Affiliates from using your information to market to you</li>
                            <li>Sharing for non-affiliates to market to you</li>
                        </ul>
                    </BodyText>
                </AiP>
            </AccordionItem>
            <AccordionItem>
                <AiH><AiB>Definitions</AiB></AiH>
                <AiP>
                    <BodyHeading>Affiliates</BodyHeading>
                    <BodyText>
                        Companies related by common ownership or control. They can be financial and nonfinancial companies.
                        <br/>
                        Fig Loans Texas, LLC's affiliates include Fig Tech, Inc., and any other affiliates that we may add in the future.
                    </BodyText>
                    <BodyHeading>Nonaffiliates</BodyHeading>
                    <BodyText>
                        Companies not related by common ownership or control. They can be financial and nonfinancial companies.
                        <br/>
                        Non-affiliates we share with can include financial services companies such as insurance agencies or mortgage brokers, nonfinancial companies such as retailers, travel companies and membership groups, other companies such as nonprofit groups.   
                    </BodyText>
                    <BodyHeading>Joint marketing</BodyHeading>
                    <BodyText>
                        A formal agreement between nonaffiliated financial companies that together market financial products or services to you.
                        <br/>
                        Our joint marketing partners include financial service and other companies such as technology companies
                    </BodyText>
                </AiP>
            </AccordionItem>
            <AccordionItem>
                <AiH><AiB>Other Important Information</AiB></AiH>
                <AiP>
                    <BodyText>
                        TX Residents: Fig Loans Texas, LLC by state law is subject to regulatory oversight by the State of Texas- Office of Consumer Credit Commissioner. Any consumer wishing to file a complaint against Fig Loans should contact the Office of Consumer Credit Commissioner through one of the means indicated below: In Person or U.S. Mail: 2601 North Lamar Boulevard, Austin, Texas 78705-4207. Telephone No.: (800) 538-1579. Fax No.: (512) 936-7610. E-mail: consumer.complaints@occc.state.tx.us. Website: www.occc.state.tx.us.
                    </BodyText>
                    <BodyText>
                        MO Residents: Fig Loans Texas LLC is also subject to regulation by the State of Missouri—Missouri Division of Finance. Call the Missouri Division of Finance or write for credit information or assistance with credit problems: Missouri Division of Finance, PO Box 716, Jefferson City, MO. 65102-0716; https://finance.mo.gov/; (573) 751-3463
                    </BodyText>
                    <BodyText>
                        IL Residents: Fig Loans Texas LLC is also subject to regulation by the State of Illinois—Illinois Department of Financial and Professional Regulation, Division of Financial Institutions. Call the Illinois Department of Financial and Professional Regulation, Division of Financial Institutions or write for credit information or assistance with credit problems Illinois Department of Financial and Professional Regulation, Division of Financial Institutions, 100 West Randolph, 9th Floor, Chicago, IL 60601; https://www.idfpr.com/DFI.asp; 1 (888) 473-4858
                    </BodyText>
                    <BodyText>
                        UT Residents: Fig Loans Texas LLC is also subject to regulation by the State of Utah— Utah Department of Financial Institutions. Call the Utah Department of Financial Institutions or write for credit information or assistance with credit problems: Utah Department of Financial Institutions, PO Box 146800, Salt Lake City, UT 84114-6800; https://dfi.utah.gov; (801) 538-8830
                    </BodyText>
                    <BodyText>
                        OH Residents: Fig Loans Texas LLC is also subject to regulation by the State of Ohio- Ohio Department of Financial Institutions. Consumers can contact the Division of Financial Institutions to file a complaint or ask a general credit-related question. Address: 77 South High Street 21st Floor, Columbus, OH 43215-6120. Phone: (614) 728-8400. Fax: (614) 728-0380. Website: www.com.ohio.gov/fiin. E-mail: web.dfi@com.ohio.gov.
                    </BodyText>
                    <BodyText>
                        Fig Loans Texas, LLC uses Plaid Technologies, Inc. (“Plaid”) to gather End User’s data from financial institutions. By using our service, you grant Fig Loans Texas, LLC and Plaid the right, power, and authority to act on your behalf to access and transmit your personal and financial information from the relevant financial institution. You agree to your personal and financial information being transferred, stored, and processed by Plaid in accordance with the Plaid Privacy Policy.
                    </BodyText>
                </AiP>
            </AccordionItem>
        </Accordion>
    )
}