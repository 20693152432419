import React, { useState} from "react";
import FormikControl from "../FormikControl";

import styled from "styled-components";

import Modal from "../../../Modal";

import Terms from "../../../Shared/Terms/Terms";

import {Link} from 'react-router-dom'

const TosLink = styled.a`
  text-decoration: underline;
  color: #3fb39d;

  &:hover {
    cursor: pointer;
  }
`;

const TosInfo = styled.p`
  font-size: 0.7rem;
  margin-top: 0;
  width: 80%;
`;

const Legal = styled.div`
  display: flex;
  flex-direction: column;
`;

const TosLinkOut = styled(Link)`
    text-decoration: underline;
    color: #3fb39d;

  &:hover {
    cursor: pointer;
  }
`;

const LegalSingle = styled.div`
    margin-top: 3rem;
    width: 100%;
`;

const validateConsent = (value) => {
  let error;
  if(value !== true){
    error = "Consent is Required"
  }
  return error;
}

function LegalInformation(props) {
  const [TosIsOpen, setTosIsOpen] = useState(false);

  const openTos = (event) => {
    // Cancel the click affecting the FormikControl:
    event.preventDefault();
    setTosIsOpen((s) => !s);
  };

  const tos = (
    <TosInfo>
      By checking this box, I hereby consent to the{" "}
      <TosLink onClick={(e) => openTos(e)}>"E-Sign Agreement"</TosLink>, the{" "}
      <TosLink onClick={(e) => openTos(e)}>
        "Credit Authorization Agreement"
      </TosLink>
      , the <TosLinkOut to="/terms-of-service">Terms of Service</TosLinkOut> and{" "}
      <TosLinkOut to="/privacy-policy">Privacy Policy</TosLinkOut>, and I am
      providing written consent under the Fair Credit Reporting Act (FCRA) for
      Fig Loans, and its partners and financial institutions to obtain consumer
      report information from my credit profile.
    </TosInfo>
  );

  if(props.single){
    return (
      <LegalSingle>
        <FormikControl
          validate={validateConsent}
          control="checkbox"
          label={tos}
          name="consentsToFcra"
        />
        <Modal open={TosIsOpen} onClose={() => setTosIsOpen(false)}>
          <Terms />
        </Modal>
      </LegalSingle>
    );
  }

  return (
    <Legal>
      <FormikControl
        validate={validateConsent}
        control="checkbox"
        label={tos}
        name="consentsToFcra"
      />
      <Modal open={TosIsOpen} onClose={() => setTosIsOpen(false)}>
        <Terms />
      </Modal>
    </Legal>
  );
}

export default LegalInformation;
