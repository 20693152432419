import moment from "moment";

export const getToken = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    console.log('EVEN TESTING ENABLED')
    return 'Bearer 66d8b39d-2eac-412c-8eb9-75cf764141aa_852e4a42-2d66-4f6e-93e1-903df599143e'
  } else {
    console.log('PRODUCTION EVEN ENABLED')
    return "Bearer 4a805a1c-0765-41b5-8a4b-39a395faf543_4d5a8ff6-8dcd-417f-9032-7c6e69ff32d9"
  }
}

export const getSavingsToken = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    console.log('Savings Testing Enabled')
    return 'Bearer ffa16a08-e0bd-4431-acd8-2f6a749aee8e_cb81e1ed-acf8-4708-a55f-35454aeb1c1a'
  } else {
    console.log('SAVINGS PRODUCTION ENABLED')
    return "Bearer ffa16a08-e0bd-4431-acd8-2f6a749aee8e_cb81e1ed-acf8-4708-a55f-35454aeb1c1a"
  }
}

const getLeadUrl = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    console.log('LEAD TESTING ENABLED')
    return "https://fig-web.new-s-route-1koobwz3hnwns-78065161.us-west-2.convox.site/api/lead";
  } else {
    console.log('PRODUCTION LEAD ENABLED')
    return "https://app.figloans.com/api/lead"
  }
}

let token = getToken();

let leadUrl = getLeadUrl();

function formatPhoneNumber(val) {
  if(val){
    let newVal = val.replace(/\D/g,'');
    return newVal;
  }
}

function formatNumber(val) {
  let newVal = val.replace(/\D/g,'');
  return parseInt(newVal);
}

// Assigns Proper Formatting Schema to Rate Table Request
const getProductTemplate = (product) => {
  switch (product) {
    case "personal-loan":
      return LoanSchema;
    case 'student-loan-refinance':
      return StudentRefiSchema;
    case 'credit-card':
      return CreditCardSchema;
    case 'savings-account':
      return SavingsSchema;
    default:
      return StudentRefiSchema;
  }
};

// Formats data to given schema to match API POST schema
export const formatJSON = (template, newData) => {
  const result = {};
  for (let key in template) {
    if (!template[key]) {
      result[key] = newData[key];
    } else {
      let nestedObj = {};
      let nestedKeys = template[key];
      nestedKeys.forEach((k) => (nestedObj[k] = newData[k]));
      result[key] = nestedObj;
    }
  }
  // Formatting Product Type for API
  if (newData["productType"]) {
    result["productTypes"] = [newData['productType'], "other"];
  }
  if(newData['productSubType']) {
    result['productSubTypes'] = [newData['productSubType']]
  }
  // Formatting Date for API
  result["personalInformation"].dateOfBirth = moment(
    result["personalInformation"].dateOfBirth
  ).format("YYYY-MM-DD");
  // Formatting Graduation Date if Exists for API
  if (result["educationInformation"].graduationDate) {
    result["educationInformation"].graduationDate = moment(
      result["educationInformation"].graduationDate
    ).format("YYYY-MM-DD");
  }
  result["personalInformation"].primaryPhone = formatPhoneNumber(
    result["personalInformation"].primaryPhone
  );
  result["loanInformation"].loanAmount = formatNumber(
    result["loanInformation"].loanAmount
  );
  result["financialInformation"].annualIncome = formatNumber(
    result["financialInformation"].annualIncome
  );
  if(result['financialInformation']['monthlyHousingPayment']){
    result['financialInformation']['monthlyHousingPayment'] = formatNumber(result['financialInformation']['monthlyHousingPayment'])
  }
  if(result['financialInformation']['availableAssets']){
    result['financialInformation']['availableAssets'] = formatNumber(result['financialInformation']['availableAssets'])
  }
  if(result['educationInformation']['graduateDegreeType'] !== 'doctorate'){
  }

  return JSON.stringify(result);
};

// Manages API Post Request
export const getRateTable = (rawData, product, utm) => {

  let template = getProductTemplate(product);

  const rateTableBody = formatJSON(template, rawData);

  if(localStorage.getItem('source') !== '') {
    utm = {
      utm_source : localStorage.getItem('source'),
      utm_medium : localStorage.getItem('medium'),
      utm_campaign : localStorage.getItem('campaign'),
      utm_content : localStorage.getItem('content'),
      utm_term : localStorage.getItem('term'),
    }
  }


  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization:
      token,
  };

  return fetch("https://api.evenfinancial.com/leads/rateTables", {
    method: "POST",
    body : rateTableBody,
    headers,
  }).then(function(res) {
    return res.json();
  }).then(function(body) {


      const uuid = body.uuid;

      const postBody = {
        uuid,
        request : rateTableBody,
        response: body,
        utm : utm,
        email : JSON.parse(rateTableBody).personalInformation.email,
      }

      let postHeader = {
        "Content-Type": "application/json",
        Accept: "application/json",
      }

      fetch(leadUrl, {
        method: 'POST',
        headers : postHeader,
        body: JSON.stringify(postBody),
      })
      return body;
    })

};

export const getLeadRateTable = (leadID) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization:
      token,
  };
  return fetch(`https://api.evenfinancial.com/originator/rateTables/${leadID}`, {
    method : 'GET',
    headers,
  }).then(function(res) {
    return res.json();
  }).then(function(body) {
    const uuid = body.uuid;

    const postHeader = {
      "Content-Type": "application/json",
      Accept: "application/json",
    }
    const postBody = {
      uuid,
      response: body
    }


    fetch(leadUrl, {
      method: 'POST',
      headers : postHeader,
      body: JSON.stringify(postBody),
    })
    return body;
  });
};

export const recordClickThrough = (uuid, url) => {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    const body = {
      uuid : uuid,
      url : url,
    }


    fetch('https://app.figloans.com/api/leads/click', {
      method : 'POST',
      headers : headers,
      body : JSON.stringify(body)
    })
}

// List of schemas for API call formatting
const LoanSchema = {
  personalInformation: [
    "firstName",
    "lastName",
    "address1",
    "address2",
    "city",
    "state",
    "zipcode",
    "email",
    "primaryPhone",
    "dateOfBirth",
    "ssn",
  ],
  loanInformation: ["purpose", "loanAmount"],
  mortgageInformation: ["propertyStatus"],
  creditInformation: ["providedCreditRating"],
  financialInformation: [
    "employmentStatus",
    "employmentPayFrequency",
    "annualIncome",
  ],
  sessionInformation: ["ipAddress"],
  educationInformation: ["educationLevel"],
  legalInformation: ["consentsToFcra", "fcraLanguage"],
  clientTags: ["source"]
};

const StudentRefiSchema = {
  personalInformation: [
    "firstName",
    "lastName",
    "address1",
    "address2",
    "city",
    "state",
    "zipcode",
    "email",
    "primaryPhone",
    "dateOfBirth",
    "ssn",
    "citizenshipStatus"
  ],
  loanInformation: ["purpose", "loanAmount"],
  mortgageInformation: ["propertyStatus"],
  creditInformation: ["providedCreditRating"],
  financialInformation: [
    "employmentStatus",
    "employmentPayFrequency",
    "annualIncome",
    'monthlyHousingPayment',
    'availableAssets'
  ],
  sessionInformation: ["ipAddress"],
  educationInformation: ["educationLevel", "graduationDate", "universityAttended", "graduateDegreeType"],
  legalInformation: ["consentsToFcra"],
  clientTags: ["source"]
}

const CreditCardSchema = {
  personalInformation: ['firstName', 'lastName', 'address1', 'address2', 'city', 'state', 'zipcode', 'email', 'dateOfBirth'],
  creditInformation: ['providedCreditRating'],
  creditCardInformation: ['cardPurposes', 'allowAnnualFee'],
  legalInformation: ["consentsToFcra"],
  clientTags: ["source"]
}

const SavingsSchema = {
  personalInformation : [
    "zipcode",
  ],
  creditInformation: [
    "providedCreditRating",
  ],
  savingsInformation: [
    "minDepositAmount",
  ]
}
