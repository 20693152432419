import React, { useState, useContext, useEffect } from "react";
import Name from "../Formik/FormComponents/Name";
import Address from "../Formik/FormComponents/Address";
import Contact from "../Formik/FormComponents/Contact";
import PersonalInfo from "../Formik/FormComponents/PersonalInfo";
import LoanInformation from "../Formik/FormComponents/LoanInformation";
import MortgageInformation from "../Formik/FormComponents/MortgageInformation";
import CreditInformation from "../Formik/FormComponents/CreditInformation";
import FinancialInformation from "../Formik/FormComponents/FinancialInformation";
import EducationInformation from "../Formik/FormComponents/EducationInformaiton";
import LegalInformation from "../Formik/FormComponents/LegalInformation";
import Title from "../Formik/FormComponents/Title";
import Stepper from "../Stepper";

import { Form, Formik } from "formik";

import Blog from "../../Blog";

import { SaveData, LoadData } from "../../../Helpers/FormData";

import "./LoanForm.css";

import TransitionModal from "../Formik/FormComponents/TransitionModal/";

import MetaDecorator from "../../Shared/MetaDecorator";

import { AppContext } from "../../../Context/Context";

import { copy } from "../../../Helpers/CopyText";

import { getInitialValues } from "../../../Helpers/FormData";

function SinglePage({ display, ...props }) {
  const [error, setError] = useState(false);

  return !display ? (
    <>
      <Formik {...props}>
        {({ errors }) => (
          <Form className="single-page-form">
            <Title text="Find a personal loan." info="You're almost there!" />
            <LoanInformation single />
            <Name single />
            <Address single />
            <Contact single />
            <FinancialInformation single />
            <CreditInformation single />
            <EducationInformation single />
            <MortgageInformation single />
            <PersonalInfo single />
            <LegalInformation single display={!display} />
            <div className="single-button-container">
              <button
                onClick={() => {
                  let errorArray = Object.keys(errors);
                  if (errorArray.length > 0) {
                    setError(true);
                  } else {
                    setError(false);
                  }
                }}
                className={
                  !error ? "form-button single" : "form-button single formerror"
                }
                type={error ? "button" : "submit"}
              >
                {error ? "Errors Present" : "Submit"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  ) : null;
}

function LoanForm({ singlePage, history, match }) {
  let fieldNames = [
    ["loanAmount", "purpose"],
    ["providedCreditRating"],
    ["firstName", "lastName"],
    ["address1", "address2", "city", "state", "zipcode"],
    ["email", "primaryPhone"],
    ["dateOfBirth", "ssn"],
    ["propertyStatus"],
    ["propertyStatus"],
    ["employmentStatus", "employmentPayFrequency", "annualIncome"],
    ["educationLevel"],
    ["consentsToFcra"],
  ];

  const initialValues = LoadData("personal-loan");

  const [display, setDisplay] = useState(false);

  const context = useContext(AppContext);
  let language = context.language;
  let copyText = copy(language);

  let product = "personal-loan";

  context.setProduct(product);
  context.setProductCode("personalLoan");

  const onSubmit = (values) => {
    setDisplay(true);
    context.setPersonalLoan(values);
    history.push("/offers");
  };

  let stepParam = match.params.step;

  let baseUrl = "/personal-loan/application/";

  return (
    <>
      <MetaDecorator
        title="FIG Market: Loan Application Form"
        content="FIG Market is here to help guide and educate you on choosing the best loan for your situation."
      />
      {singlePage ? (
        <>
          <TransitionModal source={context.source} />
          <SinglePage
            onSubmit={onSubmit}
            initialValues={getInitialValues(
              initialValues,
              context.userIp,
              localStorage.getItem("source")
            )}
            display={display}
            validateOnChange
            saveData={SaveData}
          />
        </>
      ) : !display ? (
        <Stepper
          onSubmit={onSubmit}
          initialValues={getInitialValues(
            initialValues,
            context.userIp,
            localStorage.getItem("source")
          )}
          // validationSchema={validationSchema}
          display={display}
          validateOnChange
          saveData={SaveData}
          stepParam={stepParam}
          history={history}
          context={context}
          copyText={copyText}
          fieldNames={fieldNames}
          baseUrl={baseUrl}
          product={product}
          title={"personalLoan"}
        >
          <LoanInformation product={product} />
          <CreditInformation product={product} />
          <Name product={product} />
          <Address product={product} />
          <Contact product={product} />
          <PersonalInfo product={product} />
          <MortgageInformation product={product} />
          <FinancialInformation product={product} />
          <EducationInformation product={product} />
          <LegalInformation product={product} display={!display} />
        </Stepper>
      ) : null}
      <Blog language={language} />
    </>
  );
}

export default LoanForm;
