import React from "react";

import {
  TermsContainer,
  TermNav,
  TermList,
  TermItem,
  TermLink,
  TermContainer,
} from "./Styles/Terms";

import {
  CreditAuthorization,
  EConsent,
} from "./TermText";

function Terms() {
  return (
    <TermsContainer>
      <TermNav>
        <TermList>
          <TermItem>
            <TermLink href="#CA">Credit Authorization</TermLink>
          </TermItem>
          <TermItem>
            <TermLink href="#CONSENT">Consent Agreement</TermLink>
          </TermItem>
        </TermList>
      </TermNav>
      <TermContainer id="CA">
        <CreditAuthorization/>
      </TermContainer>
      <TermContainer id="CONSENT">
        <EConsent/>
      </TermContainer>
    </TermsContainer>
  );
}

export default Terms;
